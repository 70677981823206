import React from "react";

import {
    section as sectionClass,
    overwrite,
    grid,
    titleText,
    subtitleText,
    contentText,
    button as buttonClass
} from './candidate-faq.module.scss';

import {ISection, ISectionContentButton} from "../../models/section.model";

import Section from "../hoc/section";

interface IClientFaqProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

const ClientFaq: React.FC<IClientFaqProps> = ({className, TitleTag, section}) => {

    const { content, style } = section;
    const { texts, buttons } = content;
    const [title, subtitle, description] = texts;
    const button: Partial<ISectionContentButton> = buttons[0] ?? {};

    return (
        <Section
            className={`${sectionClass} ${overwrite} ${className}`}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
                button: buttonClass,
            }}
            TitleTag={TitleTag}
            title={title}
            subtitle={subtitle}
            description={description}
            buttonText={button.text}
            buttonUrl={button.url}
            style={style}
            roundedSection={true}
        />
    )
}

export default ClientFaq;
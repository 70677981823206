import SectionHeaderCandidate from '../components/sections/header-candidate';
import SectionCandidateFaq from '../components/sections/candidate-faq';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql, navigate } from 'gatsby';

import { layout, loader } from '../templates/candidate.module.scss';
import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ISetting } from '../models/setting.model';
import { ECandidateStatus, ICandidate } from '../models/candidate.model';
import { getNodes } from '../utils/get-nodes';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getSectionsWithRepeatIndex from '../utils/get-sections-with-repeat-index';
import getCustomStyleFromSetting from '../utils/get-custom-style-from-settings';
import { useCandidate } from '../hooks/use-candidate';
import { isBrowser } from '../utils/is-browser';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import ThemeStyle from '../components/atoms/theme-style';
import Loader from '../components/atoms/loader';

type TRedirectPaths = Record<
    'login-candidate' | 'verification-candidate' | 'onboarding' | 'candidate-dashboard',
    string
>;

interface ICandidateProps {
    readonly data: {
        page: IPage;
        allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'type'>>;
        allThemeSetting: IQueryAllResult<ISetting>;
    };
}

const Candidate: React.FC<ICandidateProps> = ({ data }) => {
    const { page, allPage, allThemeSetting } = data;
    const themeSettings = getNodes(allThemeSetting);
    const customStyle = getCustomStyleFromSetting(themeSettings);
    const candidate = useCandidate();

    const { mainSectionIndex } = getPageConfigFromSections(page.sections);
    const sections = getSectionsWithRepeatIndex(page.sections);

    const redirectPaths = Object.fromEntries(
        getNodes(allPage).map(({ type, pathname }) => [type, pathname])
    ) as TRedirectPaths;

    if (!candidate.isLoggedIn && isBrowser()) {
        navigate(redirectPaths['login-candidate'] || '/', { replace: true });
    }

    if (candidate.data && candidate.isLoggedIn) {
        redirectCandidate(candidate.data, redirectPaths);
    }

    return (
        <MainLayout className={layout}>
            <ThemeStyle style={customStyle} />
            {candidate.get.isLoading && <Loader className={loader} />}
            {candidate.data &&
                sections.map((section, index) => {
                    return (
                        <SectionFactory
                            key={`section-${section.type}-${section.sectionId}`}
                            SectionComponent={sectionComponents[section.type]}
                            section={section}
                            TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                        />
                    );
                })}
        </MainLayout>
    );
};

function redirectCandidate(candidate: ICandidate, redirectPaths: TRedirectPaths) {
    if (!isBrowser()) return;

    const pathname = window.location.pathname;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const jobOfferParam = params.get('jobOffer');

    let redirectPath;
    if (candidate.status === ECandidateStatus.notVerified) {
        redirectPath = `${redirectPaths['verification-candidate']}${search}`;
    }

    if (
        candidate.status === ECandidateStatus.verifiedMissingSurvey &&
        params.get('redirect') !== 'none'
    ) {
        params.set('step', 'introduction');
        const newSearch = params.toString();
        redirectPath = `${redirectPaths.onboarding}?${newSearch}`;
    }

    if (
        candidate.status === ECandidateStatus.hasSurveyMissingTest &&
        params.get('redirect') !== 'none'
    ) {
        params.set('step', 'survey');
        params.set('modal', 'candidate-test-redirect-modal');
        const newSearch = params.toString();
        redirectPath = `${redirectPaths.onboarding}?${newSearch}`;
    }

    if (candidate.status === ECandidateStatus.hasSurveyPendingTest) {
        params.set('step', 'result');
        params.set('test', 'pending');
        const newSearch = params.toString();
        redirectPath = `${redirectPaths.onboarding}?${newSearch}`;
    }

    if (
        candidate.status >= ECandidateStatus.hasTestMissingApplication &&
        Object.values(redirectPaths)
            .filter((path) => path !== redirectPaths['candidate-dashboard'])
            .includes(pathname) &&
        params.get('step') !== 'result'
    ) {
        if (jobOfferParam) {
            redirectPath = jobOfferParam;
        } else {
            redirectPath = redirectPaths['candidate-dashboard'];
        }
    }

    if (!redirectPath || redirectPath === `${pathname}${search}`) return;

    navigate(redirectPath, { replace: true });
}

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        allPage(
            filter: {
                type: {
                    in: [
                        "login-candidate"
                        "verification-candidate"
                        "onboarding"
                        "candidate-dashboard"
                    ]
                }
                locale: { eq: $locale }
            }
        ) {
            edges {
                node {
                    pathname
                    type
                }
            }
        }
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
        allThemeSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
    }
`;

export default Candidate;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header-candidate': SectionHeaderCandidate,
    'candidate-faq': SectionCandidateFaq,
    'footer': SectionFooter,
};